<template>
  <div id="v-pills-education" role="tabpanel" aria-labelledby="v-pills-education-tab">
    <div class="col content">
      <h5 class="text-primary p-3">Highest education qualification details<span class="float-right"><span id="ij" @click="editHighQualification"><img :src="$S3_url+'/Assets/images/edit-icon-black.svg'" alt="edit education" title="edit education" width="25"></span></span></h5>           
        <hr>
        <form @submit="updateHighestQualification">
          <div class="p-4" id="i" v-if="!edit_highQualification">
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Course<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="highest_education.course_id">{{getCourse(highest_education.course_id)}}</p>
                <p v-else>-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Specification<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="highest_education.specification">{{getSpecification(highest_education.specification)}}</p>
                <p v-else>-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">State<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="highest_education.state_id">{{getState(highest_education.state_id)}}</p>
                <p v-else>-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">College<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="highest_education.institute_id">{{getInstitute(highest_education.institute_id)}}</p>
                <p v-else>-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Course Type<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="highest_education.course_type">{{getCourseType(highest_education.course_type)}}</p>
                <p v-else>-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="highest_education.passout_year">{{highest_education.passout_year}}</p>
                <p v-else>-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="highest_education.mark">{{highest_education.mark}}%</p>
                <p v-else>-</p>
              </div>
            </div>
          </div>
          <div class="p-4" id="j" v-if="edit_highQualification">
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Course<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-autocomplete v-model="highest_education.course_id" :searchable="true" :items="courses" item-text="text" item-value="id" @change="getSpecifications" @input="validate.checkIfEmpty(highest_education.course_id, 'highest_course', 'Enter course')" outlined>
                  <template slot="item" slot-scope="data">
                    <template>
                      <v-list-item-content>{{data.item.text}}</v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <div class="error-feedback" v-if ="(typeof validate.response.highest_course != 'undefined' && !validate.response.highest_course.val)">
                  {{ validate.response.highest_course.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Specification<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-autocomplete id="label-specialisation" v-model="highest_education.specification" :items="spec" item-value="value" item-text="text" @input="validate.checkIfEmpty(highest_education.specification, 'highest_speci', 'Enter specification')" outlined></v-autocomplete>
                <div class="error-feedback" v-if ="(typeof validate.response.highest_speci != 'undefined' && !validate.response.highest_speci.val)">
                  {{ validate.response.highest_speci.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">State<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-autocomplete v-model="highest_education.state_id" :items="states" item-text="text" item-value="id" @change="getInstitutes" @input="validate.checkIfEmpty(highest_education.state_id, 'highest_state', 'Enter state')" clearable outlined></v-autocomplete>
                <div class="error-feedback" v-if ="(typeof validate.response.highest_state != 'undefined' && !validate.response.highest_state.val)">
                  {{ validate.response.highest_state.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">College<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-autocomplete id="label-universities" v-model="highest_education.institute_id" :items="institutes" @input="validate.checkIfEmpty(highest_education.institute_id, 'highest_university', 'Select college')" item-value="value" item-text="text" outlined></v-autocomplete>
                <div class="error-feedback" v-if ="(typeof validate.response.highest_university != 'undefined' && !validate.response.highest_university.val)">
                  {{ validate.response.highest_university.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Course Type<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-autocomplete id="label-course-type" v-model="highest_education.course_type" :items="course_types" item-value="value" item-text="text" @input="validate.checkIfEmpty(highest_education.course_type, 'highest_course_type', 'Select course type')" outlined></v-autocomplete>
                <div class="error-feedback" v-if ="(typeof validate.response.highest_course_type != 'undefined' && !validate.response.highest_course_type.val)">
                  {{ validate.response.highest_course_type.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field type="month" v-model="highest_passout" name="year" @input="validate.checkIfEmpty(highest_passout, 'highest_passout', 'Select passout year')" outlined></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.highest_passout != 'undefined' && !validate.response.highest_passout.val)">
                  {{ validate.response.highest_passout.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field type="number" v-model="highest_education.mark" name="Marks" min="0" max="100" @input="validate.checkIfEmpty(highest_education.mark, 'highest_marks', 'Enter mark')" outlined></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.highest_marks != 'undefined' && !validate.response.highest_marks.val)">
                  {{ validate.response.highest_marks.msg }}
                </div>
              </div>
            </div>
            <div class="error-feedback" v-if="highqual_error">{{highqual_err_msg}}</div>
            <div class="row mb-1">
                <!-- <div class="col-lg-4"><p class="text-primary mt-3" style="cursor: pointer;">Add more qualification</p></div> -->
                <!-- <div class="col-lg-2"><p class="text-primary mt-3" style="cursor: pointer;">Remove</p></div> -->
              <div class="col-lg-12">
                <button class="btn btn-primary float-right">Save</button>
              </div>
            </div>
          </div>
        </form>
        <hr>
        <h5 class="text-primary p-3">More qualification details<span class="float-right"><button id="kl" class="btn btn-primary" @click="addMoreQuali">Add</button></span></h5>           
        <hr>
        <form @submit="updateMoreQualification">
          <div class="p-4" id="l" v-if="add_moreQualification">
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Course<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-autocomplete v-model="more_qualification.course_id" :searchable="true" :items="courses" item-text="text" item-value="id" @change="getSpecifications" @input="validate.checkIfEmpty(more_qualification.course_id, 'more_course', 'Enter course')" outlined>
                  <template slot="item" slot-scope="data">
                    <template>
                      <v-list-item-content>{{ data.item.text}}</v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <div class="error-feedback" v-if ="(typeof validate.response.more_course != 'undefined' && !validate.response.more_course.val)">
                  {{ validate.response.more_course.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Specification<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-autocomplete id="label-specialisation" v-model="more_qualification.specification_id" :items="spec" item-value="value" item-text="text" @input="validate.checkIfEmpty(more_qualification.specification_id, 'more_speci', 'Enter specification')" outlined></v-autocomplete>
                <div class="error-feedback" v-if ="(typeof validate.response.more_speci != 'undefined' && !validate.response.more_speci.val)">
                  {{ validate.response.more_speci.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">State<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-autocomplete v-model="more_qualification.state_id" :items="states" item-text="text" item-value="id" @change="getInstitutes" @input="validate.checkIfEmpty(more_qualification.state_id, 'more_state', 'Enter state')" clearable outlined></v-autocomplete>
                <div class="error-feedback" v-if ="(typeof validate.response.more_state != 'undefined' && !validate.response.more_state.val)">
                  {{ validate.response.more_state.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">College<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                  <v-autocomplete id="label-universities" v-model="more_qualification.institute_id" :items="institutes" item-value="value" item-text="text" @input="validate.checkIfEmpty(more_qualification.institute_id, 'more_university', 'Select College')" outlined></v-autocomplete>
                  <div class="error-feedback" v-if ="(typeof validate.response.more_university != 'undefined' && !validate.response.more_university.val)">
                    {{ validate.response.more_university.msg }}
                  </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Course Type<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-autocomplete id="label-course-type" v-model="more_qualification.course_type" :items="course_types" item-value="value" item-text="text" @input="validate.checkIfEmpty(more_qualification.course_type, 'more_course_type', 'Select course type')" outlined></v-autocomplete>
                <div class="error-feedback" v-if ="(typeof validate.response.more_course_type != 'undefined' && !validate.response.more_course_type.val)">
                  {{ validate.response.more_course_type.msg }}
                </div>
            </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                  <v-text-field type="month" v-model="more_passout" name="year" @input="validate.checkIfEmpty(more_passout, 'more_passout', 'Select passout year')" outlined></v-text-field>
                  <div class="error-feedback" v-if ="(typeof validate.response.more_passout != 'undefined' && !validate.response.more_passout.val)">
                    {{ validate.response.more_passout.msg }}
                  </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field type="number" v-model="more_qualification.mark" name="Marks" min="0" max="100" @input="validate.checkIfEmpty(more_qualification.mark, 'more_marks', 'Enter mark')" outlined></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.more_marks != 'undefined' && !validate.response.more_marks.val)">
                  {{ validate.response.more_marks.msg }}
                </div>
              </div>
            </div>
            <div class="error-feedback" v-if="morequal_error">{{morequal_err_msg}}</div>
            <div class="row mb-1">
              <div class="col-lg-12">
                <button class="btn btn-primary float-right">Save</button>
              </div>
            </div>
            <hr>
          </div>
          <div v-if="more_quals&&more_quals.length">
            <div v-for="(qual,index) in more_quals" :key="qual.id">
              <!-- <div class="col-lg-12">
                <button class="btn btn-primary float-right" @click.prevent="updateMore(qual, index)">Edit</button>
              </div> -->
              <div class="p-4" id="k">
                <div class="row">
                  <div class="col-lg-4"><label class="text-secondary">Course<span style="color: red">*</span></label></div>
                  <div class="col-lg-8">
                    <p v-if="qual.course">{{qual.course.course_name}}</p>
                    <p v-else>-</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4"><label class="text-secondary">Specification<span style="color: red">*</span></label></div>
                  <div class="col-lg-8">
                    <p v-if="qual.branch">{{qual.branch.branch_name}}</p>
                    <p v-else>-</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4"><label class="text-secondary">State<span style="color: red">*</span></label></div>
                  <div class="col-lg-8">
                    <p v-if="qual.state">{{qual.state.state_name}}</p>
                    <p v-else>-</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4"><label class="text-secondary">College<span style="color: red">*</span></label></div>
                  <div class="col-lg-8">
                    <p v-if="qual.institute">{{qual.institute.institute_name}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4"><label class="text-secondary">Course Type<span style="color: red">*</span></label></div>
                  <div class="col-lg-8">
                    <p v-if="qual.course_type"><span>{{getCourseType(qual.course_type)}}</span></p>
                    <p v-else>-</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
                  <div class="col-lg-8">
                    <p v-if="qual.passout_year">{{qual.passout_year}}</p>
                    <p v-else>-</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
                  <div class="col-lg-8">
                    <p v-if="qual.aggregate_marks">{{qual.aggregate_marks}}%</p>
                    <p v-else>-</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-1"><button id="remove-company" class="btn btn-danger" @click.prevent="removeQualification(qual.id)">Remove</button></div>
                </div>
                <hr v-if="(more_quals.length-1) != index">
              </div>
            </div>
          </div>
          <div class="text-center" v-else>
            <h6 class="mb-5" style="color:#727272"><strong>No more qualification added</strong></h6>
          </div>
        </form>
        <hr>
        <h5 class="text-primary p-3">12th details<span class="float-right"><button id="mn" class="btn btn-primary" @click="edit_xiith = !edit_xiith">Edit</button></span></h5>           
        <hr>
        <form @submit="updateXiithDetails">
          <div class="p-4" id="m" v-if="!edit_xiith">
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">School<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="xiith_details.xiith_school_name">{{xiith_details.xiith_school_name}}</p>
                <p v-if="!xiith_details.xiith_school_name">-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">CBSE/State/ICSC<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="xiith_details.xiith_board_name">{{xiith_details.xiith_board_name}}</p>
                <p v-if="!xiith_details.xiith_board_name">-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="xiith_details.xiith_passout_year">{{xiith_details.xiith_passout_year}}</p>
                <p v-if="!xiith_details.xiith_passout_year">-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="xiith_details.xiith_marks">{{xiith_details.xiith_marks}}%</p>
                <p v-if="!xiith_details.xiith_marks">-</p>
              </div>
            </div>
          </div>
          <div class="p-4" id="n" v-if="edit_xiith">
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">School<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field v-model="xiith_details.xiith_school_name" name="School" @input="validate.checkIfEmpty(xiith_details.xiith_school_name, 'xii_school', 'Enter school')" outlined></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.xii_school != 'undefined' && !validate.response.xii_school.val)">
                  {{ validate.response.xii_school.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">CBSE/State/ICSC<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field v-model="xiith_details.xiith_board_name" name="Board" @input="validate.checkIfEmpty(xiith_details.xiith_board_name, 'xii_board', 'Enter board')" outlined></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.xii_board != 'undefined' && !validate.response.xii_board.val)">
                  {{ validate.response.xii_board.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field type="month" v-model="xiith_passout" name="year" @input="validate.checkIfEmpty(xiith_passout, 'xii_passout', 'Select passout year')" outlined></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.xii_passout != 'undefined' && !validate.response.xii_passout.val)">
                  {{ validate.response.xii_passout.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field type="number" v-model="xiith_details.xiith_marks" name="Marks" min="0" max="100" @input="validate.checkIfEmpty(xiith_details.xiith_marks, 'xii_marks', 'Enter mark')" outlined></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.xii_marks != 'undefined' && !validate.response.xii_marks.val)">
                  {{ validate.response.xii_marks.msg }}
                </div>
              </div>
            </div>
            <div class="error-feedback" v-if="xii_error">{{xii_err_msg}}</div>
            <div class="row mb-1">
              <div class="col-lg-12">
                <button class="btn btn-primary float-right">Save</button>
              </div>
            </div>
          </div>
        </form>
        <hr>
        <h5 class="text-primary p-3">10th details<span class="float-right"><button id="op" class="btn btn-primary" @click="edit_xth = !edit_xth">Edit</button></span></h5>           
        <hr>
        <form @submit="updateXthDetails">
          <div class="p-4" id="o" v-if="!edit_xth">
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">School<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="xth_details.xth_school_name">{{xth_details.xth_school_name}}</p>
                <p v-if="!xth_details.xth_school_name">-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">CBSE/State/ICSC<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="xth_details.xth_board_name">{{xth_details.xth_board_name}}</p>
                <p v-if="!xth_details.xth_board_name">-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="xth_details.xth_passout_year">{{xth_details.xth_passout_year}}</p>
                <p v-if="!xth_details.xth_passout_year">-</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <p v-if="xth_details.xth_marks">{{xth_details.xth_marks}}%</p>
                <p v-if="!xth_details.xth_marks">-</p>
              </div>
            </div>
          </div>

          <div class="p-4" id="p" v-if="edit_xth">
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">School<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field v-model="xth_details.xth_school_name" name="School" @input="validate.checkIfEmpty(xth_details.xth_school_name, 'x_school', 'Enter school')" outlined ></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.x_school != 'undefined' && !validate.response.x_school.val)">
                  {{ validate.response.x_school.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">CBSE/State/ICSC<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field v-model="xth_details.xth_board_name" name="Board" @input="validate.checkIfEmpty(xth_details.xth_board_name, 'x_board', 'Enter board')" outlined></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.x_board != 'undefined' && !validate.response.x_board.val)">
                  {{ validate.response.x_board.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field type="month" v-model="xth_passout" name="year" outlined @input="validate.checkIfEmpty(xth_passout, 'x_passout', 'Select passout year')"></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.x_passout != 'undefined' && !validate.response.x_passout.val)">
                  {{ validate.response.x_passout.msg }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
              <div class="col-lg-8">
                <v-text-field type="number" v-model="xth_details.xth_marks" name="Marks" min="0" max="100" @input="validate.checkIfEmpty(xth_details.xth_marks, 'x_marks', 'Enter mark')" outlined></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.x_marks != 'undefined' && !validate.response.x_marks.val)">
                  {{ validate.response.x_marks.msg }}
                </div>
              </div>
            </div>
            <div class="error-feedback" v-if="x_error">{{x_err_msg}}</div>
            <div class="row mb-1">
              <div class="col-lg-12">
                <button class="btn btn-primary float-right">Save</button>
              </div>
            </div>
          </div>
        </form> 
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header hide-backdrop centered>
      <div class="container text-center">Updated</div>
    </b-modal>
    <b-modal id="remove" ref="remove" size="sm" title=" " body-bg-variant="danger" bod body-text-variant="light" hide-footer hide-header hide-backdrop centered>
      <div class="container text-center">Removed</div>
    </b-modal>
  </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
import validation from '../libraries/validFormat' ;
import '../assets/hire.css';
export default {
    name:'EducationDetails',
    props: ["user"],
    data: function() {
      return {
        validate: new validation(),
        action: new Actions(),
        states:[],
        courses:[],
        spec:[],
        institutes:[],
        xiith_passout:null,
        xth_passout:null,
        highest_passout:null,
        more_passout:null,
        can_update:0,
        edit_xth:false,
        edit_xiith:false,
        edit_highQualification:false,
        edit_moreQualification:false,
        add_moreQualification:false,
        c_type:null,
        set_course:null,
        set_specification:null,
        set_state:null,
        set_institute:null,
        highest_education:{
          course_id : null,
          specification : null,
          state_id : null,
          institute_id : null,
          course_type : null,
          passout_year : null,
          passout_month:null,
          mark : null
        },
        highest_qualification:{
          course:null,
          specification : null,
          state:null,
          institute:null,
          course_type:null
        },
        more_qualification:{
          course_id:null,
          specification_id:null,
          state_id:null,
          institute_id:null,
          course_type:null,
          passing_year:null,
          passing_month:null,
          mark:null
        },
        more_quals:[],
        xiith_details:{
          xiith_school_name:null,
          xiith_board_name:null,
          xiith_passout_year:null,
          xiith_passout_month:null,
          xiith_marks:null,
        },
        xth_details:{
          xth_school_name:null,
          xth_board_name:null,
          xth_passout_year:null,
          xth_passout_month:null,
          xth_marks:null,
        },
        course_types:[
          {value:1, text:'Full time'},
          {value:2, text:'Part time',}
        ],
        highqual_error : false,
        highqual_err_msg : null,
        morequal_error : false,
        morequal_err_msg : null,
        xii_error : false,
        xii_err_msg : null,
        x_error : false,
        x_err_msg : null,
      }
    },
    beforeMount(){
      this.getStates();
      this.setHighestQualification();
      // this.setMoreQualification();
      // this.states = this.$store.state.states.locations.locations;
      this.courses = this.$store.state.courses.courses.courses;
      // this.xth_details = ;
      this.setXiithDetails();
      this.setXthDetails();
      this.more_quals = this.user?.more_qualification;
    },
    methods: {
      addMoreQuali(e){
        e.preventDefault();
        this.add_moreQualification = (this.add_moreQualification)?false:((this.more_quals.length < 5)?true:false)
      },
      getCourseType(data){
        this.course_types.forEach(type => {
          if(type.value == data){
            this.c_type = type.text;
          }
        });
        return this.c_type;
      },
      getCourse(data){
        this.courses.forEach(course => {
          if(course.id == data){
            this.set_course =course.text;
          }
        });
        return this.set_course;
      },
      getState(data){
        this.states.forEach(state => {
          if(state.id == data){
            this.set_state = state.text;
          }
        });
        return this.set_state;
      },
      getSpecification(data){
        this.spec.forEach(s => {
          if(s.value == data){
            this.set_specification = s.text;
          }
        });
        return this.set_specification;
      },
      getInstitute(data){
        this.institutes.forEach(uni => {
          if(uni.value == data){
            this.set_institute = uni.text;
          }
        });
        return this.set_institute;
      },
      editHighQualification(){
        this.edit_highQualification = !this.edit_highQualification;
        this.getSpecifications();
      },
      setHighestQualification(){
        console.log(this.user);
        this.highest_education.course_id = (this.user.high_qualification?.course?.course_id)?this.user.high_qualification?.course?.course_id:null;
        this.highest_education.specification =  (this.user.high_qualification?.branch?.bid)?this.user.high_qualification?.branch?.bid:null;
        console.log(this.highest_education.specification);
        this.getSpecifications();
        this.highest_education.state_id = (this.user.high_qualification?.state?.state_id)?this.user.high_qualification?.state?.state_id:null;
        this.getInstitutes();
        this.highest_education.institute_id = (this.user.high_qualification?.institute?.institute_id)?this.user.high_qualification?.institute?.institute_id:null;
        this.highest_education.course_type = (this.user.high_qualification?.course_type)?this.user.high_qualification?.course_type:null;
        this.highest_education.passout_year = (this.user.high_qualification?.passout_year)?this.user.high_qualification?.passout_year:null;
        this.highest_education.passout_month = (this.user.high_qualification?.passout_month)?this.user.high_qualification?.passout_month:null;
        this.highest_education.mark = (this.user.high_qualification?.aggregate_marks)?this.user.high_qualification?.aggregate_marks:null;
        this.highest_passout = (this.highest_education.passout_month && this.highest_education.passout_year)? ((this.highest_education.passout_month == '11' ||this.highest_education.passout_month == '12')?this.highest_education.passout_year+'-'+this.highest_education.passout_month:this.highest_education.passout_year+'-0'+this.highest_education.passout_month): null;
        this.highest_qualification.specification = (this.user.high_qualification?.branch?.bid)?this.user.high_qualification?.branch?.branch_name:null;
        this.highest_qualification.state = (this.user.high_qualification?.state?.state_name)?this.user.high_qualification?.state?.state_name:null;
        this.highest_qualification.institute = (this.user.high_qualification?.institut?.institute_name)?this.user.high_qualification?.institute?.institute_name:null;
        this.course_types.forEach(type => {
          if(type.value == this.highest_education.course_type){
            this.highest_qualification.course_type = type.text;
          }
        });
      },
      setXthDetails(){
        this.xth_details.xth_school_name = (this.user.xth_details?.xth_school_name) ? this.user.xth_details?.xth_school_name : null;
        this.xth_details.xth_board_name = (this.user.xth_details?.xth_board_name) ? this.user.xth_details?.xth_board_name : null;
        this.xth_details.xth_passout_year = (this.user.xth_details?.xth_passout_year) ? this.user.xth_details?.xth_passout_year : null;
        this.xth_details.xth_passout_month = (this.user.xth_details?.xth_passout_month) ? this.user.xth_details?.xth_passout_month : null;
        this.xth_details.xth_marks = (this.user.xth_details?.xth_marks) ? this.user.xth_details?.xth_marks : null;
        this.xth_passout = (this.xth_details.xth_passout_month && this.xth_details.xth_passout_year)? ((this.xth_details.xth_passout_month == '11' ||this.xth_details.xth_passout_month == '12')?this.xth_details.xth_passout_year+'-'+this.xth_details.xth_passout_month:this.xth_details.xth_passout_year+'-0'+this.xth_details.xth_passout_month): null;
      },
      setXiithDetails(){
        this.xiith_details.xiith_school_name = (this.user.xiith_details?.xiith_school_name) ? this.user.xiith_details?.xiith_school_name : null;
        this.xiith_details.xiith_board_name = (this.user.xiith_details?.xiith_board_name) ? this.user.xiith_details?.xiith_board_name : null;
        this.xiith_details.xiith_passout_year = (this.user.xiith_details?.xiith_passout_year) ? this.user.xiith_details?.xiith_passout_year : null;
        this.xiith_details.xiith_passout_month = (this.user.xiith_details?.xiith_passout_month) ? this.user.xiith_details?.xiith_passout_month : null;
        this.xiith_details.xiith_marks = (this.user.xiith_details?.xiith_marks) ? this.user.xiith_details?.xiith_marks : null;
        this.xiith_passout = (this.xiith_details.xiith_passout_month && this.xiith_details.xiith_passout_year)? ((this.xiith_details.xiith_passout_month == '11' ||this.xiith_details.xiith_passout_month == '12')?this.xiith_details.xiith_passout_year+'-'+this.xiith_details.xiith_passout_month:this.xiith_details.xiith_passout_year+'-0'+this.xiith_details.xiith_passout_month): null;
      },
      getStates(){
        this.action.getStates().then(data => {
          data.states.forEach((element) => {
            this.states.push({
              id: element.state_id,
              text:element.state_name
            })
          })
        })
      },
      getSpecifications: function () {
        this.spec = [];
        let spec_id = (this.edit_highQualification)?this.highest_education.course_id:((this.add_moreQualification)?this.more_qualification.course_id:this.highest_education.course_id);
        this.action.getSpec(spec_id).then((data) => {
          data.specifications.forEach((element) => {
            this.spec.push({
              value: element.bid,
              text: element.branch_name,
            });
          });
        });
      },
      getInstitutes: function () {
        this.institutes = [];
        let state_id = (this.edit_highQualification)?this.highest_education.state_id:((this.add_moreQualification)?this.more_qualification.state_id:null);
        this.action.getInstitutes({state_id:state_id}).then((data) => {
          if(data.institutes){
            data.institutes.forEach((element) => {
              if(element.institute_name){
                this.institutes.push({
                  value: element.institute_id,
                  text:element.institute_name
                })
              }
            })
          }
        })
      },
      updateHighestQualification(e){
        e.preventDefault();
        this.can_update = 0;
        this.highqual_error = false;
        this.highqual_err_msg = null;
        if(this.validate.checkIfEmpty(this.highest_education.course_id, "highest_course", 'Enter course')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.highest_education.specification, "highest_speci", 'Enter specification')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.highest_education.state_id, "highest_state", 'Enter state')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.highest_education.institute_id, "highest_university", 'Select college')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.highest_education.course_type, "highest_course_type", 'Select course type')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.highest_passout, "highest_passout", 'Select passout year')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.highest_education.mark, "highest_marks", 'Enter mark')?.val === false){
          this.can_update++;
        }

        if(this.can_update == 0){
          this.highest_education.passout_year = this.highest_passout.split('-')[0];
          this.highest_education.passout_month = this.highest_passout.split('-')[1];
          let token = this.$cookies.get("hire-seeker").token;
          this.action.updateHighestQualification(this.highest_education,token).then(data =>{
            if(data.message == 'High Qualification updated'){
              this.edit_highQualification = false;
              this.$refs["success"].show();
              setTimeout(() => {
                this.$refs["success"].hide();
              },2000);
            }else{
              this.highqual_error = true;
              this.highqual_err_msg = "Network error! Please check your internet connection.";
            }
          }).catch(err =>{
            console.log(err);
            this.highqaul_error = true;
            if(window.navigator.onLine){
              this.highqual_err_msg = "System error! Please try again.";
            }else{
              this.highqual_err_msg = "Network error! Please check your internet connection.";
            }
          })
        }else{
          this.highqual_error = true;
          this.highqual_err_msg = "Fill all mandatory fields.";
        }
      },
      updateMoreQualification(e){
        e.preventDefault();
        this.can_update = 0;
        this.morequal_error = false;
        this.morequal_err_msg = null;

        if(this.validate.checkIfEmpty(this.more_qualification.course_id, "more_course", 'Enter course')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.more_qualification.specification_id, "more_speci", 'Enter specification')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.more_qualification.state_id, "more_state", 'Enter state')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.more_qualification.institute_id, "more_university", 'Select college')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.more_qualification.course_type, "more_course_type", 'Select course type')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.more_passout, "more_passout", 'Select passout year')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.more_qualification.mark, "more_marks", 'Enter mark')?.val === false){
          this.can_update++;
        }

        if(this.can_update == 0){
          this.more_qualification.passing_year = this.more_passout.split('-')[0];
          this.more_qualification.passing_month = this.more_passout.split('-')[1];
          let token = this.$cookies.get("hire-seeker").token;
          this.action.updateMoreQualification(this.more_qualification,token).then(res =>{
            if(res.message == 'More Qualification updated'){
              this.edit_moreQualification = false;
              this.add_moreQualification = false;
              this.$refs["success"].show();
              setTimeout(() => {
                this.$refs["success"].hide();
              },2000);
              this.more_quals.push(res.data);
              this.resetFields();
              // location.reload();
            }else{
              this.morequal_error = true;
              this.morequal_err_msg = "System error! Please try again.";
            }
          }).catch(err =>{
            console.log(err);
            this.morequal_error = true;
            if(window.navigator.onLine){
              this.morequal_err_msg = "System error! Please try again.";
            }else{
              this.morequal_err_msg = "Network error! Please check your internet connection.";
            }
          })
        }else{
          this.moreqaul_error = true;
          this.moreqaul_err_msg = "Fill all mandatory fields.";
        }
      },
      updateXiithDetails(e){
        e.preventDefault();
        this.can_update = 0;
        this.xii_error = false;
        this.xii_err_msg = null;
        
        if(this.validate.checkIfEmpty(this.xiith_details.xiith_school_name, "xii_school", 'Enter school')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.xiith_details.xiith_board_name, "xii_board", 'Enter board')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.xiith_passout, "xii_passout", 'Select passout year')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.xiith_details.xiith_marks, "xii_marks", 'Enter mark')?.val === false){
          this.can_update++;
        }

        if(this.can_update == 0){
          this.xiith_details.xiith_passout_year = this.xiith_passout.split('-')[0];
          this.xiith_details.xiith_passout_month = this.xiith_passout.split('-')[1];
          let token = this.$cookies.get("hire-seeker").token;
          this.action.updateXiithDetails(this.xiith_details,token).then(data =>{
            if(data.message == '12th School details updated'){
              this.edit_xiith = false;
              this.$refs["success"].show();
              setTimeout(() => {
                this.$refs["success"].hide();
              },2000);
            }else{
              this.xii_error = true;
              this.xii_err_msg = "System error! Please try again.";
            }
          }).catch(err =>{
            console.log(err);
            this.xii_error = true;
            if(window.navigator.onLine){
              this.xii_err_msg = "System error! Please try again.";
            }else{
              this.xii_err_msg = "Network error! Please check your internet connection.";
            }
          })
        }else{
          this.xii_error = true;
          this.xii_err_msg = "Fill all mandatory fields.";
        }
      },
      updateXthDetails(e){
        e.preventDefault();
        this.can_update = 0;
        this.x_error = false;
        this.x_err_msg= null;

        if(this.validate.checkIfEmpty(this.xth_details.xth_school_name, "x_school", 'Enter school')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.xth_details.xth_board_name, "x_board", 'Enter board')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.xth_passout, "x_passout", 'Select passout year')?.val === false){
          this.can_update++;
        }
        if(this.validate.checkIfEmpty(this.xth_details.xth_marks, "x_marks", 'Enter mark')?.val === false){
          this.can_update++;
        }

        if(this.can_update == 0){
          this.xth_details.xth_passout_year = this.xth_passout.split('-')[0];
          this.xth_details.xth_passout_month = this.xth_passout.split('-')[1];
          let token = this.$cookies.get("hire-seeker").token;
          this.action.updateXthDetails(this.xth_details,token).then(data =>{
            if(data.message == '10th School details updated'){
              this.edit_xth = false;
              this.$refs["success"].show();
              setTimeout(() => {
                this.$refs["success"].hide();
              },2000);
            }else{
              this.x_error = true;
              this.x_err_msg = "System error! Please try again.";
            }
          }).catch(err =>{
            console.log(err);
            this.x_error = true;
            if(window.navigator.onLine){
              this.x_err_msg = "System error! Please try again.";
            }else{
              this.x_err_msg = "Network error! Please check your internet connection.";
            }
          })
        }else{
          this.x_error = true;
          this.x_err_msg = "Fill all mandatory fields.";
        }
      },
      removeQualification(id){
        this.action.removeQualification(id,this.$cookies.get("hire-seeker").token).then(res => {
          if(res.message == 'Qualification deleted successfully'){
            this.more_quals = this.more_quals.filter(qual => qual.id !== id);
            this.$refs["remove"].show();
              setTimeout(() => {
                this.$refs["remove"].hide();
              },2000);
          }
        });
      },
      resetFields(){
        this.more_qualification.course_id=null,
        this.more_qualification.specification_id=null,
        this.more_qualification.state_id=null,
        this.more_qualification.institute_id=null,
        this.more_qualification.course_type=null,
        this.more_qualification.passing_year=null,
        this.more_qualification.passing_month=null,
        this.more_qualification.mark=null
      }
    }

}
</script>

<style scoped>
.error-feedback{width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;position: absolute;}
</style>